<template>
  <div class="login-container">
    <el-card class="form-box">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
        <div class="title-container">
          <h3 class="title"><svg-icon icon-class="logo" />Apple 产品知识库</h3>
        </div>
        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input ref="username" v-model="loginForm.username" placeholder="账号" name="username" type="text" tabindex="1" :maxlength="30" autocomplete="on" />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              :maxlength="50"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
        </el-tooltip>

        <el-button :loading="loading" round type="primary" style="width: 100%; margin-bottom: 30px" @click.native.prevent="handleLogin">Login</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt'

const publicKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCjJlru9r0Yn1-rUiYKj7HIJ-_qWAu3_94VNc2rJKNhYOPyJvCr3C3YG-xCY0POmAC7aJ9kix7mHmipgvHA4AvV__3un2nq-Y5KkvdYme1spyqOCSgPKyWtvFmpIDWYw_f_YsFPyHfp05AmVP11rY8PPKOgpszGEoOnmdlje1jWcQIDAQAB'

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) callback(new Error('请输入用户姓名'))
      else callback()
      // if (!validUsername(value)) {
      //   callback(new Error('请输入正确的用户姓名'))
      // } else {
      //   callback()
      // }
    }

    const validatePassword = (rule, value, callback) => {
      if (!value) callback(new Error('请输入密码'))
      if (value && value.length < 8) {
        callback(new Error('密码不能少于8位'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername,
            message: '请输入用户姓名',
          },
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
            message: '请输入密码',
          },
        ],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
    }
  },

  mounted() {
    const username = localStorage.getItem('username')
    const password = localStorage.getItem('password')
    this.loginForm = {
      username,
      password,
    }

    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },

  methods: {
    handleCheck(val) {
      if (val) {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.saveLoginInfo()
          }
        })
      }
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    saveLoginInfo() {
      if (this.savePass) {
        const { username, password } = this.loginForm
        localStorage.setItem('username', username)
        localStorage.setItem('password', password)
      } else {
        localStorage.removeItem('password')
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.saveLoginInfo()
          this.$store
            .dispatch('user/login', this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || '/input' })
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */

$bg: #283443;
$light_gray: #fff;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $bg;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $light_gray inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid #d6d6d6;
    // background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #000000;
  }
}
</style>

<style lang="scss" scoped>
$bg: #fafafa;
$dark_gray: #000000;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .form-box {
    position: fixed;
    min-width: 520px;
    top: 50%;
    padding: 30px;
    left: 50%;
    transform: translate(-50%, -60%);
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.04);
  }

  .login-form {
    position: relative;
    width: 520px;
    // padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #000000;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
.savePass1 {
  float: left;
  margin-bottom: 10px;
  color: rgb(57, 105, 237);
}
.savePass {
  float: right;
  margin-bottom: 10px;
}
.savePass1_title {
  margin-left: 10px;
  cursor: pointer;
}
</style>
