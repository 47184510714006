<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <el-dropdown class="avatar-container right-menu-item">
        <div class="avatar-wrapper">
          {{ userName }}
          <img src="./avatar.png" width="80px" height="80px" class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" @command="command">
        <span class="el-dropdown-link"> {{ tenantName }}<i class="el-icon-arrow-down el-icon--right" /> </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in tenantList" :key="item.corporateCode" :command="item.corporateCode">{{ item.corporateName }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          {{ userName }}
          <img src="./avatar.png" width="80px" height="80px" class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/speech-collection/index">
            <el-dropdown-item>话术收藏</el-dropdown-item>
          </router-link>
          <router-link to="/profile/index">
            <el-dropdown-item>修改密码</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'

import Screenfull from '@/components/Screenfull'
import { getData } from '@/api/data-record'
import Cookies from 'js-cookie'

export default {
  components: {
    Breadcrumb,
    Hamburger,

    Screenfull,
  },
  inject: ['reload'],
  data() {
    return {
      name: Cookies.get('corporateName') ?? '',
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device', 'type', 'tenantList', 'tenantName', 'userName']),
  },
  mounted() {
    // this.getTenantList()
    setTimeout(() => {
      const result = this.tenantList.find((item) => {
        return item.corporateName === this.tenantName
      })
      localStorage.setItem('_corporateCode', result?.corporateCode ?? '')
    }, 200)
  },
  methods: {
    // async getTenantList() {
    // const res = await getData({ url: '/api/corporate/all/corporate' })
    // const tenantList = this.type ? res.result : [{ corporateCode: null, corporateName: '全部' }].concat(res.result)
    // this.$store.commit('user/SET_TENANT_LIST', tenantList)
    // const { corporateCode, corporateName } = tenantList[0]
    // const code = this.type ? corporateCode : Cookies.get('corporateCode') ? Cookies.get('corporateCode') : null
    // const name = this.type ? corporateName : Cookies.get('corporateName') ? Cookies.get('corporateName') : '全部'
    // this.$store.commit('user/SET_CORPORATECODE', code ?? '')
    // this.$store.commit('user/SET_TENANT_NAME', name)
    // Cookies.set('corporateCode', code ?? '')
    // Cookies.set('corporateName', name)
    // this.name = name
    // },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
      this.$nextTick(() => {
        this.$store.dispatch('user/resetCorInfo')
      })
      localStorage.setItem('_corporateCode', null)
    },
    command(val) {
      const tenant = this.tenantList.find((item) => item.corporateCode === val).corporateName
      this.$store.commit('user/SET_CORPORATECODE', val ?? '')
      this.$store.commit('user/SET_TENANT_NAME', tenant)
      Cookies.set('corporateCode', val ?? '')
      localStorage.setItem('_corporateCode', val)
      Cookies.set('corporateName', tenant)
      this.name = <tenant></tenant>
      // location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
