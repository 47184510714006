<!-- eslint-disable vue/html-self-closing -->
<template>
  <el-card>
    <div>
      <el-form :inline="true" :model="form" ref="searchForm">
        <el-row :gutter="12">
          <el-col :md="5">
            <el-form-item label="问题" prop="question">
              <el-input v-model="form.question" placeholder="请输入"> </el-input>
            </el-form-item>
          </el-col>

          <el-col :md="5">
            <el-form-item label="经销商名称" prop="corporateCode">
              <el-select v-model="form.corporateCode">
                <el-option key="empty" label="全部" value=""></el-option>
                <el-option v-for="item in agentOptions" :key="item.code" :label="item.value" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :md="8">
            <el-form-item label="问题提出时间" prop="date">
              <el-date-picker type="daterange" v-model="form.date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :md="4">
            <el-form-item>
              <div class="search-btn">
                <Button label="查询" @click="onSubmit" className="btn1"></Button>
                <Button label="重置" @click="reset" :isSearch="false" className="btn"></Button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="top-btn">
        <el-button type="primary" round :loading="loading" @click="downData"> 数据导出 </el-button>
      </div>
      <!-- 列表 -->
      <el-table :data="tableData">
        <el-table-column label="经销商名称" prop="corporateName" />
        <el-table-column prop="question" label="问题" />
        <el-table-column prop="kimiFinalAnswer" label="kimi最终输出回答">
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content">{{ scope.row.kimiFinalAnswer }}</div>
              <div>{{ scope.row.kimiFinalAnswer }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column v-for="item in condiList" :prop="item.prop" :label="item.label" :key="item.prop">
          <template slot-scope="scope">
            <el-tooltip placement="top" v-if="scope.row[item.prop]">
              <div slot="content">
                <div>问题：{{ scope.row[item.prop].question }}</div>
                <div>回答：{{ scope.row[item.prop].answer }}</div>
              </div>
              <div>
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">问题：{{ scope.row[item.prop].question }}</div>
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">回答：{{ scope.row[item.prop].answer }}</div>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="questionAskTime" width="160" label="问题提出时间" />
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" style="text-align: right" />
    </div>
  </el-card>
</template>
<script>
import Pagination from '@/components/Pagination'
import Button from '@/components/customBtn'
import { submitData, getData } from '@/api/common'
import { downloadFile } from '@/utils'

export default {
  name: 'BasicData',
  components: { Button, Pagination },
  data() {
    return {
      loading: false,
      form: {
        date: [],
        corporateCode: '',
        question: '',
      },
      downloadForm: {
        date: [],
        corporateCode: '',
        question: '',
      },
      tableData: [],
      condiList: [],
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
      },
      agentOptions: [],
    }
  },
  mounted() {
    this.getAgent()
    this.getList()
  },
  methods: {
    getList() {
      const obj = {
        url: '/kimiQaRecord/getPage',
        ...this.listQuery,
        question: this.form.question,
        corporateCode: this.form.corporateCode,
        beginTime: this.form.date[0] || null,
        endTime: this.form.date[1] || null,
      }
      this.downloadForm = { ...this.form }
      getData(obj).then((res) => {
        const { kimiQaRecordVos, current, size, total, talkCountForKimi } = res.result
        this.tableData = kimiQaRecordVos.map((item) => {
          const n = { ...item }
          item.kimiQaTalkRecordVos.forEach((part, index) => {
            n['condidate' + (index + 1)] = { question: part.question, answer: part.answer }
          })
          return n
        })
        this.listQuery = { current, size }
        this.total = total
        this.condiList = []
        for (var i = 1; i <= talkCountForKimi; i++) {
          this.condiList.push({ prop: 'condidate' + i, label: '候选值' + i })
        }
        console.log(this.condiList)
      })
    },
    getAgent() {
      getData({ url: '/kimiQaRecord/getCorporateComBox' }).then((res) => {
        this.agentOptions = res.result
      })
    },
    onSubmit() {
      this.listQuery.current = 1
      this.getList()
    },

    reset() {
      this.$refs.searchForm.resetFields()
      this.getList()
    },
    async downData() {
      const res = await getData({
        url: '/kimiQaRecord/dataExport',
        responseType: 'blob',
        question: this.downloadForm.question,
        corporateCode: this.downloadForm.corporateCode,
        beginTime: this.downloadForm.date[0] || null,
        endTime: this.downloadForm.date[1] || null,
      })
      downloadFile(res)
    },
  },
}
</script>
<style lang="scss" scoped>
.top-btn {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
.search-btn {
  display: flex;
  .btn1 {
    margin-right: 8px;
  }
}
</style>
