import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'
// 富文本样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters
import Pagination from '@/components/Pagination'
import DescriptionsItem from '@/components/descriptions-item'
import Descriptions from '@/components/descriptions'
import permission from '@/directive/permission/index.js'

const userAgent = window.navigator.userAgent.toLowerCase()
const isMac = /macintosh|mac os x/i.test(userAgent)

// if (!isMac) {
//   // import('./assets/font/font.css')
//   // 如果不是Mac平台，使用自定义字体
//   document.body.style.fontFamily = 'PingFang, sans-serif '
// }

Vue.use(permission)
Vue.component('pagination', Pagination)
Vue.component(DescriptionsItem.name, DescriptionsItem)
Vue.component(Descriptions.name, Descriptions)

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})
