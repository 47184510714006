<!-- eslint-disable vue/html-self-closing -->
<template>
  <el-card>
    <div>
      <el-form :inline="true" :model="form" ref="searchForm">
        <el-row :gutter="12">
          <el-col :md="5">
            <el-form-item label="ID" prop="dataId">
              <el-input v-model="form.dataId"> </el-input>
            </el-form-item>
          </el-col>

          <el-col :md="5">
            <el-form-item label="type" prop="dataType">
              <el-select v-model="form.dataType">
                <el-option key="empty" label="全部" value=""></el-option>
                <el-option key="qa" label="QA" value="QA"></el-option>
                <el-option key="attrs" label="attrs" value="attrs"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :md="5">
            <el-form-item label="product" prop="mpn">
              <el-input v-model="form.mpn"> </el-input>
            </el-form-item>
          </el-col>

          <el-col :md="5">
            <el-form-item label="text" prop="question">
              <el-input v-model="form.question"> </el-input>
            </el-form-item>
          </el-col>

          <el-col :md="4">
            <el-form-item>
              <div class="search-btn">
                <Button label="查询" @click="onSubmit" className="btn1"></Button>
                <Button label="重置" @click="reset" :isSearch="false" className="btn"></Button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="top-btn">
        <el-button type="primary" round :loading="loading" @click="downTemplate"> 模板下载 </el-button>

        <el-upload action accept=".xls, .xlsx" :show-file-list="false" :http-request="importFile" :disabled="uploadLoading">
          <el-button round type="primary" :loading="uploadLoading">数据导入</el-button>
        </el-upload>

        <el-button type="primary" round :loading="loading" @click="downData"> 数据导出 </el-button>

        <el-button type="default" round :loading="errorLoading" @click="downErrorData"> 异常数据下载 </el-button>

        <el-button type="danger" round :loading="delloading" :disabled="!multipleSelection.length" @click="batchDel"> 批量删除 </el-button>
      </div>
      <!-- 列表 -->
      <el-table :data="tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" :selectable="allowSelected" width="55" />
        <el-table-column prop="dataId" label="ID" />
        <el-table-column prop="dataType" label="type" />
        <el-table-column prop="mpn" label="product" />
        <el-table-column prop="question" width="400" label="text" show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="answer" width="380" label="associate_text" show-overflow-tooltip="true" />
        <el-table-column prop="updateTime" width="160" label="update time" />
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" style="text-align: right" />
    </div>
  </el-card>
</template>
<script>
import Pagination from '@/components/Pagination'
import Button from '@/components/customBtn'
import { submitData, getData } from '@/api/common'
import { downloadFile } from '@/utils'

export default {
  name: 'BasicData',
  components: { Button, Pagination },
  data() {
    return {
      loading: false,
      form: {
        dataId: '',
        dataType: '',
        mpn: '',
        question: '',
      },
      downloadForm: {
        dataId: '',
        dataType: '',
        mpn: '',
        question: '',
      },
      tableData: [],
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
      },
      delloading: false,
      uploadLoading: false,
      multipleSelection: [],
      errorLoading: false,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const obj = {
        url: '/knowledge/getPageByParam',
        ...this.listQuery,
        ...this.form,
      }
      this.downloadForm = { ...this.form }
      getData(obj).then((res) => {
        const { knowledgeVoList, current, size, total } = res.result
        this.tableData = knowledgeVoList
        this.listQuery = { current, size }
        this.total = total
      })
    },
    allowSelected(row, index) {
      return row.dataType !== 'QA'
    },
    handleSelectionChange(val) {
      const ids = val.map((item) => item.id)
      this.multipleSelection = ids
    },
    batchDel() {
      const self = this
      this.$confirm('确定要批量删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: (action) => {
          if (action !== 'confirm') return
          self.delloading = true
          const obj = {
            url: '/knowledge/deleteByIds',
            requestData: { idList: self.multipleSelection },
          }
          submitData(obj)
            .then((res) => {
              self.delloading = false
              self.getList()
              self.$message.success('删除成功')
            })
            .catch(() => {
              self.delloading = false
            })
        },
      })
    },
    onSubmit() {
      this.listQuery.current = 1
      this.getList()
    },

    reset() {
      this.$refs.searchForm.resetFields()
      this.getList()
    },
    async downTemplate() {
      const res = await getData({
        url: '/knowledge/excelTemplateDownload',
        responseType: 'blob',
      })
      downloadFile(res)
    },
    async downData() {
      const res = await getData({
        url: '/knowledge/exportByParam',
        responseType: 'blob',
        ...this.downloadForm,
      })
      downloadFile(res)
    },
    importFile(file) {
      const formData = new FormData()
      formData.append('excelFile', file.file)
      this.uploadLoading = true
      submitData({
        url: '/knowledge/importFromExcelFile',
        requestData: formData,
      })
        .then((res) => {
          this.uploadLoading = false
          this.$message.success('数据上传成功')
          this.getList()
        })
        .catch(() => {
          this.uploadLoading = false
        })
    },
    async downErrorData() {
      const hasError = await getData({ url: '/knowledge/getProcessFailedRecord' })
      if (!hasError.result || !hasError.result.length) {
        this.$message.info('暂无异常数据，不可下载')
        return
      }
      this.errorLoading = true
      const res = await getData({
        url: '/knowledge/exportProcessFailedRecord',
        responseType: 'blob',
      })
      this.errorLoading = false
      downloadFile(res)
    },
  },
}
</script>
<style lang="scss" scoped>
.top-btn {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
.search-btn {
  display: flex;
  .btn1 {
    margin-right: 8px;
  }
}
</style>
