import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000, // request timeout
})

let isPoint401 = false
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // 登录接口不需要token
      if (config.url.indexOf('/sys/login') === -1) {
        config.headers['Authorization'] = getToken() || store.getters.token
      }
    }
    // console.log(store.getters.token, getToken(), '3333333333333')
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data

    if (res instanceof ArrayBuffer) {
      return res
    }
    if (res instanceof Blob) {
      return response
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === '00004' || res.code === '401') {
      store.dispatch('user/backHome').then(() => {
        location.reload()
      })
    } else if (res.code !== '100000') {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    if (error.message.includes('timeout')) {
      // 判断请求异常信息中是否含有超时timeout字符串
      return Message({
        message: '请求超时,请刷新重试',
        type: 'error',
        duration: 5 * 1000,
      })
    }
    if (error.response.status === 401) {
      if (!isPoint401) {
        isPoint401 = true
        MessageBox.confirm('登录已过期 ', '提示', {
          confirmButtonText: '重新登录',
          showCancelButton: false,
          type: 'warning',
        })
          .then(() => {
            isPoint401 = false
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          })
          .catch(() => {
            isPoint401 = false
          })
      }
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    }

    return Promise.reject(error)
  }
)

export default service
