<template>
  <div>
    <quill-editor
      :style="{ height: height ? height : '450px' }"
      v-loading="loading"
      ref="myQuillEditor"
      v-model="content"
      class="ql-editor"
      :options="editorOption"
      :class="{ renderBorder: disabled }"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      @change="onEditorChange($event)"
    />
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import { uploadAxios } from "@/api/data-record";

export default {
  name: "QuillEditor",
  components: {
    quillEditor,
  },
  props: {
    prefixCls: {
      type: String,
      default: "ant-editor-quill",
    },
    // 表单校验用字段
    // eslint-disable-next-line
    value: {
      type: String,
    },
    height: {
      type: String,
      default: "450px",
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
    options: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      content: null,
      loading: false,
      editorOption: {
        // some quill options
        placeholder: this.disabled ? "" : this.placeholder,
        readOnly: this.readOnly,
        modules: {
          toolbar: this.disabled
            ? null
            : Object.keys(this.options)
            ? this.options
            : {
                container: [
                  ["bold", "italic", "underline"],
                  [{ align: [] }],
                  // [{ indent: '-1' }, { indent: '+1' }],
                  // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                  [{ color: [] }],
                  [{ size: ["small", false, "large", "huge"] }],
                  ["image"],
                  // [{ script: 'sub' }, { script: 'super' }], // 上下标
                ],
                handlers: {
                  image: this.imageHandler,
                },
              },
        },
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.content = val;
      },
    },
    showImage: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) {
          this.editorOption.modules.toolbar.container.splice(4, 1);
        }
      },
    },
    disabled: {
      handler() {
        this.disabled && this.$refs.myQuillEditor.quill.enable(false);
        this.renderBorder();
      },
    },
  },

  mounted() {
    this.disabled && this.$refs.myQuillEditor.quill.enable(false);
  },
  methods: {
    onEditorBlur(quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', html, text)
      this.$emit("change", html, text);
    },
    imageHandler(value) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();
      input.onchange = () => {
        const params = new FormData();
        params.append("file", input.files[0]);
        this.loading = true;
        uploadAxios(params)
          .then((res) => {
            this.loading = false;
            if (!res.success) return;
            const imgSrc = res.result;
            const quill = this.$refs.myQuillEditor.quill;
            let position = quill.getSelection().index;
            quill.insertEmbed(position, "image", imgSrc);
            quill.setSelection(position + 1);
          })
          .catch(() => {
            this.loading = false;
          });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quill-editor {
  padding-left: 0;
  margin-top: -30px;
  overflow-y: visible;
}
::v-deep .ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
::v-deep .ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.renderBorder {
  ::v-deep .ql-container {
    border: none;
  }
}
</style>
