import { loginApi, getInfoApi, logoutApi } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { findValueByKey } from '@/utils/tree-format'
import router, { resetRouter } from '@/router'
import Cookies from 'js-cookie'
import { getData } from '@/api/data-record'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  roles: [],
  userId: '',
  homePage: '',
  corporateCode: '', // HQcode
  loginCorporateCode: null,
  tenantName: null, // HQ Name
  type: null, // HQ类型
  tenantList: [],
  pushInfo: {
    id: '',
    title: '',
    status: false,
    context: null,
  },
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_HOMEPAGE: (state, homePage) => {
    state.homePage = homePage
  },
  SET_CORPORATECODE: (state, corporateCode) => {
    state.corporateCode = corporateCode
  },
  SET_LOGINCORPORATECODE: (state, corporateCode) => {
    state.loginCorporateCode = corporateCode
  },
  SET_TENANT_NAME: (state, tenantName) => {
    state.tenantName = tenantName
  },
  SET_TYPE(state, type) {
    state.type = type
  },
  SET_TENANT_LIST: (state, tenantList) => {
    state.tenantList = tenantList
  },
  SET_PUSH_INFO: (state, pushInfo) => {
    state.pushInfo = { ...state.pushInfo, ...pushInfo }
  },
}

const actions = {
  // user login
  login({ commit, dispatch }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      loginApi({ username: username.trim(), password: password })
        .then((response) => {
          const { result } = response
          commit('SET_TOKEN', result.token)
          setToken(result.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPush({ commit, state }) {
    return new Promise((resolve, reject) => {
      getData({ url: '/pushMessage/get' }).then((res) => {
        const { status, message = {} } = res.result
        const { title = '', context = '', id } = message ?? {}
        const obj = {
          title,
          context,
          id,
          status,
        }
        commit('SET_PUSH_INFO', obj)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfoApi(state.token)
        .then((response) => {
          const { result } = response

          if (!result) {
            reject('验证失败，请重新登录.')
          }

          const {
            userInfo: { avatar, userName, userId, corporateCode },
            roleInfo,
            type,
          } = result
          // roles must be a non-empty array
          if (!roleInfo || roleInfo.length <= 0) {
            reject('用户无访问权限!')
          }
          if (roleInfo[0].code === 'data-record') {
            commit('SET_HOMEPAGE', '/')
          } else {
            commit('SET_HOMEPAGE', `/${roleInfo[0].code}`)
          }
          const getIdArr = findValueByKey([...roleInfo], 'code', 'childMenu')
          let roles = []
          roles = getIdArr

          const formatUserInfo = {
            roles,
            avatar,
            userName,
          }

          commit('SET_ROLES', ['admin'])
          commit('SET_USERID', userId)
          commit('SET_LOGINCORPORATECODE', corporateCode)
          commit('SET_NAME', userName)
          commit('SET_TYPE', type)

          commit('SET_AVATAR', '')
          resolve(formatUserInfo)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      logoutApi()
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  backHome({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
  resetCorInfo({ commit }) {
    commit('SET_CORPORATECODE', null)
    commit('SET_TENANT_NAME', null)
    commit('SET_TENANT_LIST', [])
    Cookies.remove('corporateCode')
    Cookies.remove('corporateName')
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true,
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
