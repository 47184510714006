<template>
  <el-card>
    <el-form v-loading="loading" :inline="true" :model="form" ref="searchForm" :rules="rules">
      <div class="title">
        <span>话术查询接口</span>
      </div>
      <div class="content">
        <el-switch v-model="form.searchApi" style="margin-right: 20px"></el-switch>
        <el-input-number v-model="form.searchTimeLimit" :disabled="!form.searchApi" :precision="0" :min="0" :controls="false"></el-input-number>
        <span>秒内，拉取的次数超过</span>
        <el-input-number v-model="form.searchPullLimit" :disabled="!form.searchApi" :precision="0" :min="0" :controls="false"></el-input-number>
        <span>次，对应经销商在</span>
        <el-input-number v-model="form.searchDuration" :disabled="!form.searchApi" :precision="0" :min="0" :controls="false"></el-input-number>
        <span>分钟内无法访问</span>
      </div>
      <div class="title">
        <span>产品对比接口</span>
      </div>
      <div class="content">
        <el-switch v-model="form.compareApi" style="margin-right: 20px"></el-switch>
        <el-input-number v-model="form.compareTimeLimit" :disabled="!form.compareApi" :precision="0" :min="0" :controls="false"></el-input-number>
        <span>秒内，拉取的次数超过</span>
        <el-input-number v-model="form.comparePullLimit" :disabled="!form.compareApi" :precision="0" :min="0" :controls="false"></el-input-number>
        <span>次，对应经销商在</span>
        <el-input-number v-model="form.compareDuration" :disabled="!form.compareApi" :precision="0" :min="0" :controls="false"></el-input-number>
        <span>分钟内无法访问</span>
      </div>
      <div class="title">
        <span>技术规格返参设置</span>
      </div>
      <div style="padding-top: 10px">
        <el-form-item label="iPhone" prop="iphoneParams" label-width="120px">
          <el-select v-model="form.iphoneParams" multiple style="width: 600px">
            <el-option v-for="item in iphoneOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="Mac" prop="macParams" label-width="120px">
          <el-select v-model="form.macParams" multiple style="width: 600px">
            <el-option v-for="item in macOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="Watch" prop="watchParams" label-width="120px">
          <el-select v-model="form.watchParams" multiple style="width: 600px">
            <el-option v-for="item in watchOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="iPad" prop="ipadParams" label-width="120px">
          <el-select v-model="form.ipadParams" multiple style="width: 600px">
            <el-option v-for="item in ipadOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="Airpods" prop="airpodsParams" label-width="120px">
          <el-select v-model="form.airpodsParams" multiple style="width: 600px">
            <el-option v-for="item in airpodsOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="Vision" prop="visionParams" label-width="120px">
          <el-select v-model="form.visionParams" multiple style="width: 600px">
            <el-option v-for="item in visionOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="配件" prop="partsParams" label-width="120px">
          <el-select v-model="form.partsParams" multiple style="width: 600px">
            <el-option v-for="item in partsOptions" :label="item.fieldDesc" :key="item.fieldName" :value="item.fieldName"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="title">
        <span>RAG接口</span>
      </div>
      <div style="padding-top: 10px">
        <el-form-item label="查询类型" prop="isMultiRoundChat" label-width="120px">
          <el-radio-group v-model="form.isMultiRoundChat">
            <el-radio label="0">单轮</el-radio>
            <el-radio label="1">多轮</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="LLM Model" prop="llmModel" label-width="120px">
          <el-select v-model="form.llmModel" style="width: 300px">
            <el-option label="moonshot-v1-8k" value="0"></el-option>
            <el-option label="moonshot-v1-32k" value="1"></el-option>
            <el-option label="moonshot-v1-128k" value="2"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="Model Key" prop="apiKey" label-width="120px">
          <el-input v-model="form.apiKey" style="width: 600px"></el-input>
        </el-form-item>
      </div>

      <div class="title">
        <span>输出结果过滤</span>
      </div>
      <div v-for="(item, index) in form.kimiOutputFilterContentList" :key="index">
        <el-form-item label=" " label-width="120px">
          <el-input v-model="item.value" style="width: 600px"></el-input>
          <i class="el-icon-plus" v-if="index === 0" @click="handleFilterAdd" style="font-size: 20px; margin-left: 10px; cursor: pointer"></i>
          <i class="el-icon-minus" v-if="index !== 0" @click="handleFilterDecr(index)" style="font-size: 20px; margin-left: 10px; cursor: pointer"></i>
        </el-form-item>
      </div>
      <div class="search-btn">
        <el-form-item label=" " label-width="120px">
          <Button label="提交" @click="onSubmit" className="btn1"></Button>
        </el-form-item>
      </div>
    </el-form>
  </el-card>
</template>
<script>
import Button from '@/components/customBtn'
import { getData, submitData } from '@/api/common'

const nameToOption = {
  Watch: 'watchOptions',
  iPhone: 'iphoneOptions',
  Mac: 'macOptions',
  iPad: 'ipadOptions',
  AirPods: 'airpodsOptions',
  Vision: 'visionOptions',
  配件: 'partsOptions',
}
const nameToParams = {
  Watch: 'watchParams',
  iPhone: 'iphoneParams',
  Mac: 'macParams',
  iPad: 'ipadParams',
  AirPods: 'airpodsParams',
  Vision: 'visionParams',
  配件: 'partsParams',
}

export default {
  name: 'SystemConfig',
  components: { Button },
  data() {
    return {
      form: {
        searchApi: false,
        searchTimeLimit: 0,
        searchPullLimit: 0,
        searchDuration: 0,
        compareApi: false,
        compareTimeLimit: 0,
        comparePullLimit: 0,
        compareDuration: 0,
        isMultiRoundChat: '0',
        llmModel: '0',
        apiKey: '',
        iphoneParams: [],
        macParams: [],
        watchParams: [],
        ipadParams: [],
        airpodsParams: [],
        visionParams: [],
        partsParams: [],
        kimiOutputFilterContentList: [],
      },
      rules: {
        isMultiRoundChat: [
          {
            required: true,
            message: '请选择',
          },
        ],
        llmModel: [
          {
            required: true,
            message: '请选择',
          },
        ],
        apiKey: [
          {
            required: true,
            message: '请输入',
          },
        ],
        iphoneParams: [
          {
            required: true,
            message: '请选择',
          },
        ],
        iphoneParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
        macParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
        watchParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
        ipadParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
        airpodsParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
        visionParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
        partsParams: [
          {
            required: true,
            type: 'array',
            message: '请选择',
          },
        ],
      },
      loading: false,
      iphoneOptions: [],
      watchOptions: [],
      macOptions: [],
      ipadOptions: [],
      airpodsOptions: [],
      visionOptions: [],
      partsOptions: [],
    }
  },
  mounted() {
    this.getOptions()
    this.getConfig()
  },
  methods: {
    getConfig() {
      getData({ url: '/sysConfig/getConfigs' }).then((res) => {
        res.result.apiLimitConfigList.forEach((item) => {
          if (item.apiType === 0) {
            this.form.searchApi = item.enableStatus === 1
            this.form.searchTimeLimit = item.measureTime
            this.form.searchPullLimit = item.requestCountLimit
            this.form.searchDuration = item.apiLimitTime
          } else if (item.apiType === 1) {
            this.form.compareApi = item.enableStatus === 1
            this.form.compareTimeLimit = item.measureTime
            this.form.comparePullLimit = item.requestCountLimit
            this.form.compareDuration = item.apiLimitTime
          }
        })
        this.form.isMultiRoundChat = res.result.ragConfig.isMultiRoundChat + ''
        this.form.llmModel = res.result.ragConfig.llmModel + ''
        this.form.apiKey = res.result.ragConfig.apiKey
        this.form.kimiOutputFilterContentList = res.result.kimiOutputFilterContentList.length ? res.result.kimiOutputFilterContentList.map((item) => ({ value: item })) : [{ value: '' }]
        res.result.productTechnologySpecComBoxVoList.forEach((item) => {
          this.form[nameToParams[item.productTypeName]] = item.comBoxItemVoList.map((part) => part.fieldName)
        })
      })
    },
    getOptions() {
      getData({ url: '/sysConfig/getProductTechnologySpecComBox' }).then((res) => {
        res.result.forEach((item) => {
          this[nameToOption[item.productTypeName]] = item.comBoxItemVoList
        })
      })
    },
    onSubmit() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          const {
            searchApi,
            searchTimeLimit,
            searchPullLimit,
            searchDuration,
            compareApi,
            compareTimeLimit,
            comparePullLimit,
            compareDuration,
            isMultiRoundChat,
            llmModel,
            apiKey,
            kimiOutputFilterContentList,
          } = this.form
          const selectedList = []
          for (let key in nameToParams) {
            selectedList.push({
              productTypeName: key,
              fieldNameList: this.form[nameToParams[key]],
            })
          }
          const params = {
            productCompareConfig: { selectedList },
            kimiOutputFilterConfig: { filterContentList: kimiOutputFilterContentList.map((item) => item.value).filter((item) => item) },
            knowledgeQueryApiLimitConfig: {
              apiType: 0,
              enableStatus: searchApi ? 1 : 0,
              measureTime: searchTimeLimit,
              requestCountLimit: searchPullLimit,
              apiLimitTime: searchDuration,
            },
            productSpuCompareApiLimitConfig: {
              apiType: 1,
              enableStatus: compareApi ? 1 : 0,
              measureTime: compareTimeLimit,
              requestCountLimit: comparePullLimit,
              apiLimitTime: compareDuration,
            },
            ragConfig: {
              isMultiRoundChat,
              llmModel,
              apiKey,
            },
          }
          this.loading = true
          submitData({ url: '/sysConfig/updateConfigs', ...params })
            .then((res) => {
              this.loading = false
              if (res.success) {
                this.$message.success('系统配置成功')
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    handleFilterAdd() {
      this.form.kimiOutputFilterContentList.push({ value: '' })
    },
    handleFilterDecr(index) {
      this.form.kimiOutputFilterContentList.splice(index, 1)
    },
  },
}
</script>
<style scoped>
.title {
  font-weight: bold;
  margin-top: 10px;
}
.title span {
  margin-right: 10px;
}
.content {
  line-height: 80px;
}
.content span {
  margin: 0 5px;
}
</style>
