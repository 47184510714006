<template>
  <div id="app">
    <router-view v-if="isReload" />
  </div>
</template>

<script>
import { logoutApi } from '@/api/user'
import { mapMutations } from 'vuex'
import { setToken } from '@/utils/auth'

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isReload: true,
    }
  },
  mounted() {
    // 处理外部系统跳转进来
    this.dealUrlParams()

    let beginTime = 0 // 执行onbeforeunload的开始时间
    let differTime = 0 // 时间差
    window.onunload = function () {
      differTime = new Date().getTime() - beginTime
      if (differTime <= 5) {
        console.log('浏览器关闭')
        logoutApi()
      } else {
        console.log('浏览器刷新')
      }
    }
    window.onbeforeunload = function () {
      beginTime = new Date().getTime()
    }
  },
  methods: {
    ...mapMutations(['user/SET_TOKEN', 'user/SET_TYPE', 'user/SET_CORPORATECODE', 'user/SET_ROLES']),
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    },
    dealUrlParams() {
      const { params } = Object.fromEntries(new URLSearchParams(location.search))
      if (!params) return
      const { token, corporateCode, type, routerpath } = JSON.parse(params)
      this['user/SET_TOKEN'](token)
      setToken(token)
      this['user/SET_TYPE'](type)
      this['user/SET_CORPORATECODE'](corporateCode)

      this['user/SET_ROLES'](['data-record-query', 'data-record-copy', 'data-record-share'])

      localStorage.setItem('_corporateCode', corporateCode)
      // const timeout = setTimeout(() => {
      // clearTimeout(timeout)
      this.$router.replace(routerpath)
      // }, 90)
    },
  },
}
</script>
