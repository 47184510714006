<template>
  <quill-editor
    :value="value"
    :disabled="disabled"
    :options="options"
    :height="height"
    @change="change"
  />
</template>

<script>
import QuillEditor from "./QuillEditor.vue";

export default {
  components: { QuillEditor },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: {},
    },
    height: {
      type: String,
      default: "450px",
    },
  },
  methods: {
    change(value, text) {
      this.$emit("change", value);
    },
  },
};
</script>
