var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("quill-editor", {
    attrs: {
      value: _vm.value,
      disabled: _vm.disabled,
      options: _vm.options,
      height: _vm.height,
    },
    on: { change: _vm.change },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }