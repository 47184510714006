import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  // 系统路由
  {
    path: '/',
    component: Layout,
    redirect: '/input',
    name: 'data-export',
    meta: {
      title: '知识录入',
      icon: 'table',
      noCache: false,
      roles: ['admin'],
    },
    children: [
      {
        path: 'input',
        component: () => import('@/views/data-export/index'),
        name: 'DataExportIndex', // 用于缓存，对应页面name
        meta: {
          title: '知识录入',
          icon: 'el-icon-edit',
          noCache: false,
          roles: ['admin'],
        },
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    redirect: '/record',
    name: 'Require',
    meta: {
      title: '调用记录',
      icon: 'table',
      noCache: false,
      roles: ['admin'],
    },
    children: [
      {
        path: 'record',
        component: () => import('@/views/require-record/index'),
        name: 'RequireRecord', // 用于缓存，对应页面name
        meta: {
          title: '调用记录',
          icon: 'el-icon-date',
          noCache: false,
          roles: ['admin'],
        },
      },
    ],
  },
  {
    path: '/systemConfig',
    component: Layout,
    redirect: '/systemConfig/index',
    name: 'system-config',
    meta: {
      title: '系统配置',
      icon: 'table',
      noCache: false,
      roles: ['admin'],
    },
    children: [
      {
        path: '/systemConfig/index',
        component: () => import('@/views/sys-config/index'),
        name: 'searchCompareConfig', // 用于缓存，对应页面name
        meta: {
          title: '系统配置',
          icon: 'el-icon-setting',
          noCache: false,
          roles: ['admin'],
        },
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true },
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
