const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  userId: (state) => state.user.userId,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  userName: (state) => state.user.name,
  roles: (state) => state.user.roles,
  homePage: (state) => state.user.homePage,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  type: (state) => state.user.type,
  tenantList: (state) => state.user.tenantList,
  tenantName: (state) => state.user.tenantName,
  corporateCode: (state) => state.user.corporateCode,
  loginCorporateCode: (state) => state.user.loginCorporateCode,
}
export default getters
