var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("quill-editor", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "myQuillEditor",
        staticClass: "ql-editor",
        class: { renderBorder: _vm.disabled },
        style: { height: _vm.height ? _vm.height : "450px" },
        attrs: { options: _vm.editorOption },
        on: {
          blur: function ($event) {
            return _vm.onEditorBlur($event)
          },
          focus: function ($event) {
            return _vm.onEditorFocus($event)
          },
          ready: function ($event) {
            return _vm.onEditorReady($event)
          },
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }