/* eslint-disable semi */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
// eslint-disable-next-line semi
import request from '@/utils/request'

// top5
export function hotTop5(type) {
  const code = localStorage.getItem('_corporateCode')
  const params = { type }

  if (code === 'null') delete params.code
  else params.code = code

  return request({
    url: `/query/count/hotTop5`,
    method: 'get',
    params,
  })
}

/* ---------------产品列表-------------- */
// 产品热搜词
export function getHotTop5Api(params) {
  return request({
    url: '/products/hotTop5',
    method: 'get',
    params,
  })
}

// 产品搜索历史
export function getSerachHistoryApi(params) {
  return request({
    url: '/products/history',
    method: 'get',
    params,
  })
}
// 产品列表
export function getProductListApi(params) {
  return request({
    url: '/products/queryList',
    method: 'get',
    params,
  })
}
// 产品列表详情
export function getProductDetailApi(params) {
  return request({
    url: '/products/details',
    method: 'get',
    params,
  })
}
// 复制
export function productCopyApi() {
  return request({
    url: '/products/adopt',
    method: 'post',
  })
}
/* ---------------技术规格-------------- */
// 技术规格列表
export function getTechnicalListApi(params) {
  return request({
    url: '/technology/queryList',
    method: 'get',
    params,
  })
}
// 搜索历史
export function getTechnicalHistoryApi(params) {
  return request({
    url: '/technology/history',
    method: 'get',
    params,
  })
}
// 热搜词
export function getTechnologyHotTop5Api(params) {
  return request({
    url: '/technology/hotTop5',
    method: 'get',
    params,
  })
}
// 复制
export function technologyCopyApi() {
  return request({
    url: '/technology/adopt',
    method: 'post',
  })
}

export function technologyAdopt(data) {
  return request({
    url: '/technology/adopt',
    method: 'post',
    data,
  })
}

// 详情
export function getTechnologyDetailApi({ id }) {
  return request({
    url: `/technology/getInfo/${id}`,
    method: 'get',
  })
}

/* ---------------软件兼容-------------- */

// 软件兼容列表
export function getSoftwareListApi(params) {
  return request({
    url: '/compatibility/soft/data/list',
    method: 'get',
    params,
  })
}
// 软件兼容详情
export function getSoftwareDetailtApi(params) {
  return request({
    url: '/compatibility/soft/soft/detail',
    method: 'get',
    params,
  })
}
// 复制
export function softwareCopyApi() {
  return request({
    url: '/compatibility/soft/adopt',
    method: 'post',
  })
}

// 搜索历史
export function getSoftwarelHistoryApi(params) {
  return request({
    url: '/compatibility/soft/history',
    method: 'get',
    params,
  })
}
// 热搜词
export function getSoftwarelHotTop5Api(params) {
  return request({
    url: '/compatibility/soft/hot/words',
    method: 'get',
    params,
  })
}
// 添加缺失软件
export function addMissSoftwareApi(data) {
  return request({
    url: '/compatibility/soft/add/soft',
    method: 'post',
    data,
  })
}

/* ---------------配件兼容-------------- */

// 列表
export function getPartsListApi(params) {
  return request({
    url: '/compatible-accessories/queryList',
    method: 'get',
    params,
  })
}
// 详情
export function getPartsDetailtApi(params) {
  return request({
    url: '/compatible-accessories/get',
    method: 'get',
    params,
  })
}
// 复制
export function partsCopyApi() {
  return request({
    url: '/compatible-accessories/adopt',
    method: 'post',
  })
}

// 搜索历史
export function getPartsHistoryApi(params) {
  return request({
    url: '/compatible-accessories/history',
    method: 'get',
    params,
  })
}
// 热搜词
export function getPartsHotTop5Api(params) {
  return request({
    url: '/compatible-accessories/hotTop5',
    method: 'get',
    params,
  })
}
/* ---------------产品对比-------------- */

// 列表
export function getProductCompareListApi(params) {
  return request({
    url: '/product/comparison/query',
    method: 'get',
    params,
  })
}

// 复制
export function productCompareCopyApi() {
  return request({
    url: '/product/comparison/adopt',
    method: 'post',
  })
}

// 搜索历史
export function getproductCompareHistoryApi(params) {
  return request({
    url: '/product/comparison/history',
    method: 'get',
    params,
  })
}
// 热搜词
export function getProductCompareHotTop5Api(params) {
  return request({
    url: '/product/comparison/hotTop5',
    method: 'get',
    params,
  })
}
// 对比
export function compareApi(data) {
  return request({
    url: '/product/comparison/start',
    method: 'post',
    data,
  })
}
export function getData(params) {
  const { url, responseType = 'JSON', ...reset } = params
  return request({
    url,
    params: { ...reset },
    method: 'get',
    responseType: responseType,
  })
}
export function getDataPost(params) {
  const { url, responseType = 'JSON', ...reset } = params
  return request({
    url,
    data: { ...reset },
    method: 'post',
    responseType: responseType,
  })
}
// 提交数据
export function submitData(params) {
  const { url, method = 'post', requestData, responseType = 'json', ...reset } = params
  return request({
    url: url,
    method: method,
    data: requestData || { ...reset },
    responseType: responseType,
  })
}

// 话术列表历史记录查询
export function talkGetHistory() {
  return request({
    url: '/dataQuery/talk/getHistory',
    method: 'get',
  })
}

export function isTalkInfoExist(params) {
  return request({
    url: '/dataQuery/talk/isTalkInfoExist',
    method: 'get',
    params,
  })
}

// 获取折叠内容
export function getTalkInfoType() {
  return request({
    url: '/dataQuery/talk/getTalkInfoType',
    method: 'get',
  })
}

// 话术列表
export function talkGetPage(params) {
  return request({
    url: '/dataQuery/talk/get',
    method: 'get',
    params,
  })
}

// 话术分享复制接口
export function talkAdopt() {
  return request({
    url: '/dataQuery/talk/adopt',
    method: 'post',
  })
}

export function missTalkAdd(data) {
  return request({
    url: '/miss/talk',
    method: 'post',
    data,
  })
}

export function getTalkInfoSimilarity(params) {
  return request({
    url: '/dataQuery/talk/getTalkInfoSimilarity',
    method: 'get',
    params,
  })
}

export function getBaseInfo() {
  return request({
    url: '/dataImport/talk/getBaseInfo',
    method: 'post',
  })
}

export function updateTalkInfo(data) {
  return request({
    url: '/dataImport/talk/update/TalkInfo',
    method: 'put',
    data,
  })
}

export function talkInfoLookup(data) {
  return request({
    url: '/dataQuery/talk/talkInfoLookup',
    method: 'post',
    params: data,
  })
}

export function productsAddTop(data) {
  return request({
    url: '/products/addTop',
    method: 'post',
    params: data,
  })
}

export function softAddTop(data) {
  return request({
    url: '/compatibility/soft/soft/addTop',
    method: 'post',
    params: data,
  })
}

export function accessoriesAddTop(data) {
  return request({
    url: '/compatible-accessories/addTop',
    method: 'post',
    params: data,
  })
}

export function talkGetTop(params) {
  return request({
    url: '/dataQuery/talk/get/top',
    method: 'get',
    params,
  })
}

export function spuList() {
  return request({
    url: '/product/comparison/get/spu/list',
    method: 'get',
  })
}

export function spuStart(data) {
  return request({
    url: '/product/comparison/spu/start',
    method: 'post',
    data,
  })
}

export function spuTalk(data) {
  return request({
    url: '/product/comparison/spu/talk',
    method: 'post',
    data,
  })
}

export function talkTop10(params) {
  return request({
    url: '/product/comparison/spu/talk/top10',
    method: 'get',
    params,
  })
}

export function talkCompare(params) {
  return request({
    url: '/product/comparison/spu/talk/compare',
    method: 'get',
    params,
  })
}

export function talkAdoptImage(params) {
  return request({
    url: '/dataQuery/talk/adoptImage',
    method: 'post',
    params,
  })
}

export function techFieldList(data) {
  return request({
    url: '/product/comparison/spu/techField/list',
    method: 'post',
    data,
  })
}

export function imgGenerationSpuStart(data) {
  return request({
    url: '/imgGeneration/getProductCompareInfoBySpuList',
    method: 'post',
    data,
  })
}

export function productCompareAndTechMatch(data) {
  return request({
    url: '/imgGeneration/productCompareAndTechMatch',
    method: 'post',
    data,
  })
}

export function productCompareAndTechMatchHistory(data) {
  return request({
    url: '/imgGenHistoryRecord/productTechMatch',
    method: 'post',
    data,
  })
}

export function uploadAxios(formData) {
  return request({
    method: 'post',
    url: '/file/upload',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' },
  })
}

export function talkInfoCopy(talkInfoId) {
  return request({
    url: '/product/comparison/talkInfoCopy',
    method: 'get',
    params: { talkInfoId },
  })
}

export function talkImgCopy(talkInfoId) {
  return request({
    url: '/product/comparison/talkImgCopy',
    method: 'get',
    params: { talkInfoId },
  })
}

export function getQueryType(params) {
  return request({
    url: '/dataQuerySwitch/getQueryType',
    method: 'get',
    params,
  })
}

export function getTeachingManagementMenus(params) {
  return request({
    url: '/teachingManagement/getMenus',
    method: 'get',
    params,
  })
}

export function getMenuComboBox() {
  return request({
    url: '/teachingManagement/getMenuComboBox',
    method: 'get',
  })
}

export function createMenu(data) {
  return request({
    method: 'post',
    url: '/teachingManagement/createMenu',
    data,
  })
}

export function deleteMenu(params) {
  return request({
    method: 'post',
    url: '/teachingManagement/deleteMenu',
    params,
  })
}

export function uploadGIF(data, menuId) {
  return request({
    method: 'post',
    url: `/teachingManagement/uploadGIF`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function deleteFile(params) {
  return request({
    method: 'post',
    url: '/teachingManagement/deleteFile',
    params,
  })
}

export function saveQueryRecordTalkInfo(data) {
  return request({
    method: 'post',
    url: '/queryTalkRecord/saveQueryRecordTalkInfo',
    data,
  })
}

export function queryRecordTalkInfoAccept(data) {
  return request({
    method: 'post',
    url: '/queryTalkRecord/queryRecordTalkInfoAccept',
    params: data,
  })
}

export function saveQueryRecordData(data) {
  return request({
    method: 'post',
    url: '/queryTalkRecord/saveQueryRecordData',
    data: data,
  })
}

export function queryRecordDataAccept(data) {
  return request({
    method: 'post',
    url: '/queryTalkRecord/queryRecordDataAccept',
    params: data,
  })
}

// 话术收藏列表选择
export function directoryGet() {
  return request({
    method: 'get',
    url: '/collect/directory/get',
  })
}

export function recordAdd(data) {
  return request({
    method: 'post',
    url: '/collect/record/add',
    data,
  })
}

export function directoryUpdate(data) {
  return request({
    method: 'post',
    url: '/collect/directory/update',
    data,
  })
}

export function recordDelete(data) {
  return request({
    method: 'post',
    url: '/collect/record/delete',
    data,
  })
}

export function recordUpdate(data) {
  return request({
    method: 'post',
    url: '/collect/record/update',
    data,
  })
}

export function picSave(data) {
  return request({
    method: 'post',
    url: '/imgGenHistoryRecord/save',
    data,
  })
}

export function imgGenHistoryRecordGetPage(params) {
  return request({
    url: '/imgGenHistoryRecord/getPage',
    method: 'get',
    params: params,
  })
}

export function imgGenHistoryRecordDelete(imgGenHistoryRecordId) {
  return request({
    url: '/imgGenHistoryRecord/delete',
    method: 'post',
    params: { imgGenHistoryRecordId },
  })
}

export function imgGenHistoryRecordGetDetail(params) {
  return request({
    url: '/imgGenHistoryRecord/getDetail',
    method: 'post',
    data: params,
  })
}
