var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.pushInfo.title,
        visible: _vm.visible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("quillEditor", {
        attrs: { height: "650px", disabled: true },
        model: {
          value: _vm.pushInfo.context,
          callback: function ($$v) {
            _vm.$set(_vm.pushInfo, "context", $$v)
          },
          expression: "pushInfo.context",
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            { staticClass: "btns" },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.change },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                },
                [_vm._v("不再提示")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }