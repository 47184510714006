<template>
  <el-dialog
    :title="pushInfo.title"
    :visible.sync="visible"
    :close-on-click-modal="false"
  >
    <quillEditor v-model="pushInfo.context" height="650px" :disabled="true" />
    <span slot="footer" class="dialog-footer">
      <div class="btns">
        <el-checkbox v-model="checked" @change="change">不再提示</el-checkbox>

        <el-button @click="visible = false">关闭</el-button>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import quillEditor from '@/components/QuillEdit/index.vue'
import { submitData } from '@/api/data-record'
import { mapState } from 'vuex'
export default {
  components: {
    quillEditor
  },
  props: {

  },

  data() {
    return {
      visible: false,
      checked: false,
      title: '',
      context: '',
      id: ''
    }
  },
  computed: {
    ...mapState({
      pushInfo: state => state.user.pushInfo
    })
  },
  watch: {

    visible: {
      handler(val) {
        if (!val) {
          this.$store.commit('user/SET_PUSH_INFO', { status: false })
        }
      }
    }
  },
  mounted() {
    if (this.pushInfo.status) this.visible = true
  },
  methods: {

    change(val) {
      if (val) {
        const obj = {
          url: '/pushMessage/notNote',
          messageId: this.pushInfo.id
        }
        submitData(obj).then(res => {
          this.$message.success('设置成功')
          this.$store.commit('user/SET_PUSH_INFO', { status: false })
          this.visible = false
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btns{
  display: flex;
  justify-content: space-between;
}
</style>
