var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c(
      "div",
      [
        _c(
          "el-form",
          { ref: "searchForm", attrs: { inline: true, model: _vm.form } },
          [
            _c(
              "el-row",
              { attrs: { gutter: 12 } },
              [
                _c(
                  "el-col",
                  { attrs: { md: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "ID", prop: "dataId" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.dataId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "dataId", $$v)
                            },
                            expression: "form.dataId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "type", prop: "dataType" } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.form.dataType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataType", $$v)
                              },
                              expression: "form.dataType",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "empty",
                              attrs: { label: "全部", value: "" },
                            }),
                            _c("el-option", {
                              key: "qa",
                              attrs: { label: "QA", value: "QA" },
                            }),
                            _c("el-option", {
                              key: "attrs",
                              attrs: { label: "attrs", value: "attrs" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "product", prop: "mpn" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.mpn,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mpn", $$v)
                            },
                            expression: "form.mpn",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "text", prop: "question" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.question,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "question", $$v)
                            },
                            expression: "form.question",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 4 } },
                  [
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "search-btn" },
                        [
                          _c("Button", {
                            attrs: { label: "查询", className: "btn1" },
                            on: { click: _vm.onSubmit },
                          }),
                          _c("Button", {
                            attrs: {
                              label: "重置",
                              isSearch: false,
                              className: "btn",
                            },
                            on: { click: _vm.reset },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", loading: _vm.loading },
                on: { click: _vm.downTemplate },
              },
              [_vm._v(" 模板下载 ")]
            ),
            _c(
              "el-upload",
              {
                attrs: {
                  action: "",
                  accept: ".xls, .xlsx",
                  "show-file-list": false,
                  "http-request": _vm.importFile,
                  disabled: _vm.uploadLoading,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      round: "",
                      type: "primary",
                      loading: _vm.uploadLoading,
                    },
                  },
                  [_vm._v("数据导入")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", loading: _vm.loading },
                on: { click: _vm.downData },
              },
              [_vm._v(" 数据导出 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "default",
                  round: "",
                  loading: _vm.errorLoading,
                },
                on: { click: _vm.downErrorData },
              },
              [_vm._v(" 异常数据下载 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "danger",
                  round: "",
                  loading: _vm.delloading,
                  disabled: !_vm.multipleSelection.length,
                },
                on: { click: _vm.batchDel },
              },
              [_vm._v(" 批量删除 ")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            attrs: { data: _vm.tableData },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "selection",
                selectable: _vm.allowSelected,
                width: "55",
              },
            }),
            _c("el-table-column", { attrs: { prop: "dataId", label: "ID" } }),
            _c("el-table-column", {
              attrs: { prop: "dataType", label: "type" },
            }),
            _c("el-table-column", { attrs: { prop: "mpn", label: "product" } }),
            _c("el-table-column", {
              attrs: {
                prop: "question",
                width: "400",
                label: "text",
                "show-overflow-tooltip": "true",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "answer",
                width: "380",
                label: "associate_text",
                "show-overflow-tooltip": "true",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "updateTime", width: "160", label: "update time" },
            }),
          ],
          1
        ),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          staticStyle: { "text-align": "right" },
          attrs: {
            total: _vm.total,
            page: _vm.listQuery.current,
            limit: _vm.listQuery.size,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.listQuery, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.listQuery, "size", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }