var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c(
      "div",
      [
        _c(
          "el-form",
          { ref: "searchForm", attrs: { inline: true, model: _vm.form } },
          [
            _c(
              "el-row",
              { attrs: { gutter: 12 } },
              [
                _c(
                  "el-col",
                  { attrs: { md: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题", prop: "question" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.form.question,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "question", $$v)
                            },
                            expression: "form.question",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商名称", prop: "corporateCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.form.corporateCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "corporateCode", $$v)
                              },
                              expression: "form.corporateCode",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "empty",
                              attrs: { label: "全部", value: "" },
                            }),
                            _vm._l(_vm.agentOptions, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.value, value: item.code },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题提出时间", prop: "date" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "date", $$v)
                            },
                            expression: "form.date",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 4 } },
                  [
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "search-btn" },
                        [
                          _c("Button", {
                            attrs: { label: "查询", className: "btn1" },
                            on: { click: _vm.onSubmit },
                          }),
                          _c("Button", {
                            attrs: {
                              label: "重置",
                              isSearch: false,
                              className: "btn",
                            },
                            on: { click: _vm.reset },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", loading: _vm.loading },
                on: { click: _vm.downData },
              },
              [_vm._v(" 数据导出 ")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          { attrs: { data: _vm.tableData } },
          [
            _c("el-table-column", {
              attrs: { label: "经销商名称", prop: "corporateName" },
            }),
            _c("el-table-column", {
              attrs: { prop: "question", label: "问题" },
            }),
            _c("el-table-column", {
              attrs: { prop: "kimiFinalAnswer", label: "kimi最终输出回答" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v(_vm._s(scope.row.kimiFinalAnswer))]
                        ),
                        _c("div", [_vm._v(_vm._s(scope.row.kimiFinalAnswer))]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._l(_vm.condiList, function (item) {
              return _c("el-table-column", {
                key: item.prop,
                attrs: { prop: item.prop, label: item.label },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row[item.prop]
                            ? _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "问题：" +
                                            _vm._s(
                                              scope.row[item.prop].question
                                            )
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "回答：" +
                                            _vm._s(scope.row[item.prop].answer)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "问题：" +
                                            _vm._s(
                                              scope.row[item.prop].question
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "回答：" +
                                            _vm._s(scope.row[item.prop].answer)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            _c("el-table-column", {
              attrs: {
                prop: "questionAskTime",
                width: "160",
                label: "问题提出时间",
              },
            }),
          ],
          2
        ),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          staticStyle: { "text-align": "right" },
          attrs: {
            total: _vm.total,
            page: _vm.listQuery.current,
            limit: _vm.listQuery.size,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.listQuery, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.listQuery, "size", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }