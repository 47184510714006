var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "searchForm",
          attrs: { inline: true, model: _vm.form, rules: _vm.rules },
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("话术查询接口")]),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("el-switch", {
                staticStyle: { "margin-right": "20px" },
                model: {
                  value: _vm.form.searchApi,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "searchApi", $$v)
                  },
                  expression: "form.searchApi",
                },
              }),
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.form.searchApi,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                model: {
                  value: _vm.form.searchTimeLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "searchTimeLimit", $$v)
                  },
                  expression: "form.searchTimeLimit",
                },
              }),
              _c("span", [_vm._v("秒内，拉取的次数超过")]),
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.form.searchApi,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                model: {
                  value: _vm.form.searchPullLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "searchPullLimit", $$v)
                  },
                  expression: "form.searchPullLimit",
                },
              }),
              _c("span", [_vm._v("次，对应经销商在")]),
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.form.searchApi,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                model: {
                  value: _vm.form.searchDuration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "searchDuration", $$v)
                  },
                  expression: "form.searchDuration",
                },
              }),
              _c("span", [_vm._v("分钟内无法访问")]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "结果返回topn",
                    prop: "topn",
                    "label-width": "120px",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 10, step: "1", precision: "0" },
                    model: {
                      value: _vm.form.topn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "topn", $$v)
                      },
                      expression: "form.topn",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("产品对比接口")]),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("el-switch", {
                staticStyle: { "margin-right": "20px" },
                model: {
                  value: _vm.form.compareApi,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "compareApi", $$v)
                  },
                  expression: "form.compareApi",
                },
              }),
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.form.compareApi,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                model: {
                  value: _vm.form.compareTimeLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "compareTimeLimit", $$v)
                  },
                  expression: "form.compareTimeLimit",
                },
              }),
              _c("span", [_vm._v("秒内，拉取的次数超过")]),
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.form.compareApi,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                model: {
                  value: _vm.form.comparePullLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "comparePullLimit", $$v)
                  },
                  expression: "form.comparePullLimit",
                },
              }),
              _c("span", [_vm._v("次，对应经销商在")]),
              _c("el-input-number", {
                attrs: {
                  disabled: !_vm.form.compareApi,
                  precision: 0,
                  min: 0,
                  controls: false,
                },
                model: {
                  value: _vm.form.compareDuration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "compareDuration", $$v)
                  },
                  expression: "form.compareDuration",
                },
              }),
              _c("span", [_vm._v("分钟内无法访问")]),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("技术规格返参设置")]),
          ]),
          _c(
            "div",
            { staticStyle: { "padding-top": "10px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "iPhone",
                    prop: "iphoneParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.iphoneParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "iphoneParams", $$v)
                        },
                        expression: "form.iphoneParams",
                      },
                    },
                    _vm._l(_vm.iphoneOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Mac",
                    prop: "macParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.macParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "macParams", $$v)
                        },
                        expression: "form.macParams",
                      },
                    },
                    _vm._l(_vm.macOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Watch",
                    prop: "watchParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.watchParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "watchParams", $$v)
                        },
                        expression: "form.watchParams",
                      },
                    },
                    _vm._l(_vm.watchOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "iPad",
                    prop: "ipadParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.ipadParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ipadParams", $$v)
                        },
                        expression: "form.ipadParams",
                      },
                    },
                    _vm._l(_vm.ipadOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Airpods",
                    prop: "airpodsParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.airpodsParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "airpodsParams", $$v)
                        },
                        expression: "form.airpodsParams",
                      },
                    },
                    _vm._l(_vm.airpodsOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Vision",
                    prop: "visionParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.visionParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "visionParams", $$v)
                        },
                        expression: "form.visionParams",
                      },
                    },
                    _vm._l(_vm.visionOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "配件",
                    prop: "partsParams",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.form.partsParams,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "partsParams", $$v)
                        },
                        expression: "form.partsParams",
                      },
                    },
                    _vm._l(_vm.partsOptions, function (item) {
                      return _c("el-option", {
                        key: item.fieldName,
                        attrs: { label: item.fieldDesc, value: item.fieldName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("RAG接口")]),
          ]),
          _c(
            "div",
            { staticStyle: { "padding-top": "10px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "查询类型",
                    prop: "isMultiRoundChat",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isMultiRoundChat,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isMultiRoundChat", $$v)
                        },
                        expression: "form.isMultiRoundChat",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("单轮"),
                      ]),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("多轮"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "LLM Model",
                    prop: "llmModel",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.llmModel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "llmModel", $$v)
                        },
                        expression: "form.llmModel",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "moonshot-v1-8k", value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: "moonshot-v1-32k", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "moonshot-v1-128k", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { label: "Model Key", "label-width": "120px" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "600px" },
                    attrs: { placeholder: "*************", disabled: true },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "text" },
                      on: { click: _vm.handleModelKey },
                    },
                    [_vm._v("编辑")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("输出结果过滤")]),
          ]),
          _vm._l(_vm.form.kimiOutputFilterContentList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: " ", "label-width": "120px" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "600px" },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                    index === 0
                      ? _c("i", {
                          staticClass: "el-icon-plus",
                          staticStyle: {
                            "font-size": "20px",
                            "margin-left": "10px",
                            cursor: "pointer",
                          },
                          on: { click: _vm.handleFilterAdd },
                        })
                      : _vm._e(),
                    index !== 0
                      ? _c("i", {
                          staticClass: "el-icon-minus",
                          staticStyle: {
                            "font-size": "20px",
                            "margin-left": "10px",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilterDecr(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "search-btn" },
            [
              _c(
                "el-form-item",
                { attrs: { label: " ", "label-width": "120px" } },
                [
                  _c("Button", {
                    attrs: { label: "提交", className: "btn1" },
                    on: { click: _vm.onSubmit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Model Key 编辑",
            width: "800px",
            visible: _vm.modelKeyVisible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "modelKeyForm",
              attrs: {
                model: _vm.modelKeyForm,
                rules: _vm.modelKeyRules,
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "旧Model Key",
                    prop: "apisOldKey",
                    "label-width": "120px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "600px" },
                    attrs: { maxlength: 100 },
                    model: {
                      value: _vm.modelKeyForm.apisOldKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelKeyForm, "apisOldKey", $$v)
                      },
                      expression: "modelKeyForm.apisOldKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "新Model Key",
                    prop: "apisNewKey",
                    "label-width": "120px",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "600px" },
                    attrs: { maxlength: 100 },
                    model: {
                      value: _vm.modelKeyForm.apisNewKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelKeyForm, "apisNewKey", $$v)
                      },
                      expression: "modelKeyForm.apisNewKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleUpdateModelKey },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }