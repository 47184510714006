import request from '@/utils/request'
import JSEncrypt from 'jsencrypt'
import { PU_KEY } from '@/utils/publicKey'

const publicKeyInBackend = PU_KEY

const encryptor = new JSEncrypt()
encryptor.setPublicKey(publicKeyInBackend)

export function loginApi(data) {
  return request({
    url: '/sys/login',
    method: 'post',
    data: {
      loginAccount: data.username,
      password: encryptor.encrypt(data.password),
    },
  })
}
export function logoutApi() {
  return request({
    url: '/sys/logout',
    method: 'post',
  })
}

export function getInfoApi() {
  return request({
    url: '/sys/getMenuInfo',
    method: 'get',
  })
}

// 修改密码
export function updatePasswordApi(data) {
  return request({
    url: '/sys/user/changePassword',
    method: 'post',
    data: {
      ...data,
      newPassword: encryptor.encrypt(data.newPassword),
      confirmPassword: encryptor.encrypt(data.confirmPassword),
      oldPassword: encryptor.encrypt(data.oldPassword),
    },
  })
}

export function userExportAll(data) {
  return request({
    url: '/sys/user/exportAll',
    method: 'post',
    data,
    responseType: 'arraybuffer',
  })
}

// 用户列表
export function getUserListApi(params) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params: params,
  })
}

// 新增用户
export function addUserApi(data) {
  return request({
    url: '/sys/user/add',
    method: 'post',
    data: {
      ...data,
      password: encryptor.encrypt(data.password),
    },
  })
}

// 修改用户
export function editUserApi(data) {
  return request({
    url: '/sys/user/edit',
    method: 'post',
    data,
  })
}

// 删除用户
export function delUserApi(userId) {
  return request({
    url: `/sys/user/${userId}`,
    method: 'delete',
  })
}

// 用户信息详情
export function getUserDetailsApi(userId) {
  return request({
    url: `/sys/user/${userId}`,
    method: 'get',
  })
}

// 重置密码
export function resetPwdApi(data) {
  return request({
    url: '/sys/user/resetPassword',
    method: 'post',
    data,
  })
}

export function updatePasswordApiqqq(data) {
  return request({
    url: 'https://akw.digitalchina.com/apple-product-knowledge/sys/outerLogin',
    method: 'post',
    data: {
      systemFlag: 'BI系统',
    },
  })
}
